import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function Navigation() {
  return (
    <Navbar className="custom-nav" variant="dark">
      <Navbar.Brand href="/">Find a Criterion</Navbar.Brand>
      <Nav>
        <Nav.Link href="/explore">Explore</Nav.Link>
        <Nav.Link href="/browse">Browse</Nav.Link>
      </Nav>
    </Navbar>
  );
}
export default Navigation;
