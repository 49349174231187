import AmericaAmerica from '../data/themes/america_america.json';
import AmourFou from '../data/themes/amour_fou.json';
import Animals from '../data/themes/animals.json';
import AvantGarde from '../data/themes/avant_garde.json';
import BlueChristmases from '../data/themes/blue_christmases.json';
import BritishRealism from '../data/themes/british_realism.json';
import CannesBigWinners from '../data/themes/cannes_big_winners.json';
import ClassicHollywood from '../data/themes/classic_hollywood.json';
import Comedies from '../data/themes/comedies.json';
import CompareAndContrast from '../data/themes/compare_and_contrast.json';
import CultMovies from '../data/themes/cult_movies.json';
import Cut from '../data/themes/cut.json';
import Documentaries from '../data/themes/documentaries.json';
import DysfunctionalFamilies from '../data/themes/dysfunctional_families.json';
import FaithOnFilms from '../data/themes/faith_on_film.json';
import FirstFilms from '../data/themes/first_films.json';
import FoodOnFilm from '../data/themes/food_on_film.json';
import FrenchNewWave from '../data/themes/french_new_wave.json';
import GreatSoundtracks from '../data/themes/great_soundtracks.json';
import GrowingPains from '../data/themes/growing_pains.json';
import HeistMovies from '../data/themes/heist_movies.json';
import IndependentAmericanCinema from '../data/themes/independent_american_cinema.json';
import ItalianNeorealism from '../data/themes/italian_neorealism.json';
import JapaneseNewWave from '../data/themes/japanese_new_wave.json';
import LittleSomethingExtra from '../data/themes/little_something_extra.json';
import MadeDuringWorldWarII from '../data/themes/made_during_world_war_ii.json';
import Melodrama from '../data/themes/melodrama.json';
import NewAmericanCinema from '../data/themes/new_american_cinema.json';
import NewGermanCinema from '../data/themes/new_german_cinema.json';
import NewYorkStories from '../data/themes/new_york_stories.json';
import NoirAndNeonoir from '../data/themes/noir_and_neonoir.json';
import NovelsOnTheBigScreen from '../data/themes/novels_on_the_big_screen.json';
import Originals from '../data/themes/originals.json';
import OscarWinners from '../data/themes/oscar_winners.json';
import OutAtCrition from '../data/themes/out_at_criterion.json';
import PoeticRealism from '../data/themes/poetic_realism.json';
import RoadTrips from '../data/themes/road_trips.json';
import SamuraiCinema from '../data/themes/samurai_cinema.json';
import ScaryMovies from '../data/themes/scary_movies.json';
import SilentCinema from '../data/themes/silent_cinema.json';
import SilentComedies from '../data/themes/silent_comedies.json';
import SongAndDance from '../data/themes/song_and_dance.json';
import StageToScreen from '../data/themes/stage_to_screen.json';
import Suspense from '../data/themes/suspense.json';
import Tearjerkers from '../data/themes/tearjerkers.json';
import Technicolor from '../data/themes/technicolor.json';
import VirtuallyReality from '../data/themes/virtually_reality.json';
import WarFilms from '../data/themes/war_films.json';
import Westerns from '../data/themes/westerns.json';
import Yakuza from '../data/themes/yakuza.json';

export default {
  ...AmericaAmerica,
  ...AmourFou,
  ...Animals,
  ...AvantGarde,
  ...BlueChristmases,
  ...BritishRealism,
  ...CannesBigWinners,
  ...ClassicHollywood,
  ...Comedies,
  ...CompareAndContrast,
  ...CultMovies,
  ...Cut,
  ...Documentaries,
  ...DysfunctionalFamilies,
  ...FaithOnFilms,
  ...FirstFilms,
  ...FoodOnFilm,
  ...FrenchNewWave,
  ...GreatSoundtracks,
  ...GrowingPains,
  ...HeistMovies,
  ...IndependentAmericanCinema,
  ...ItalianNeorealism,
  ...JapaneseNewWave,
  ...LittleSomethingExtra,
  ...MadeDuringWorldWarII,
  ...Melodrama,
  ...NewAmericanCinema,
  ...NewGermanCinema,
  ...NewYorkStories,
  ...NoirAndNeonoir,
  ...NovelsOnTheBigScreen,
  ...Originals,
  ...OscarWinners,
  ...OutAtCrition,
  ...PoeticRealism,
  ...RoadTrips,
  ...SamuraiCinema,
  ...ScaryMovies,
  ...SilentCinema,
  ...SilentComedies,
  ...SongAndDance,
  ...StageToScreen,
  ...Suspense,
  ...Tearjerkers,
  ...Technicolor,
  ...VirtuallyReality,
  ...WarFilms,
  ...Westerns,
  ...Yakuza,
};
